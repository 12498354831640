import React from "react"

export default class LoginLink extends React.Component<any, any> {
    
    componentDidMount() {
        window.location.replace("http://play.google.com/store/apps/details?id=com.ossapp");
    }

    render() {
        return (
            <div>
                Redirecting to <a href="http://play.google.com/store/apps/details?id=com.ossapp" target="_blank" rel="noopener">http://play.google.com/store/apps/details?id=com.ossapp</a>
            </div>
        )
    }
}
